<template>
  <section>
    <b-row class="mb-5">
      <b-col
        md="9"
        class="pb-3 pb-md-0"
      >
        <h1>  {{ $t("Payments") }}</h1>
        <h5 class="text-primary">
          {{ $t("purchases.paymentList") }}
        </h5>
      </b-col>
      <b-col
        md="3"
        class="text-right"
        align-self="end"
      >
        <b-button
          variant="primary"
          @click="createPurchases()"
        >
          <span class="text-nowrap"> {{ $t("purchases.createPayments") }}</span>
        </b-button>
      </b-col>
    </b-row>

    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>{{ $t("dataGeneric.internal") }}</span>
        </template>
        <purchases-list
          :msg="true"
          class="mt-2"
        />
      </b-tab>

      <b-tab active>
        <template #title>
          <feather-icon icon="ToolIcon" />
          <span>{{ $t("dataGeneric.external") }}</span>
        </template>
        <purchases-list
          :msg="false"
          class="mt-2"
        />
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>

import {
  BTabs, BTab, BRow, BCol, BButton,
} from 'bootstrap-vue'
import PurchasesList from './PurchasesList.vue'

export default {
  components: {
    BTabs,
    BTab,
    PurchasesList,
    BRow,
    BCol,
    BButton,
  },
  methods: {
    createPurchases() {
      this.$router.push({ name: 'purchases-create' })
    },
  },
}
</script>
